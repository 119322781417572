<template>
  <div>
    <Header></Header>
    <div class="pre-introduction">
      <div class="container"><h1>OUR PROGRAM</h1>
        <h3>CODE</h3>
        <el-carousel :interval="4000" height="650px">
          <el-carousel-item v-for="item in 6" :key="item">
            <img style="width: 100%;object-fit: contain" src="https://cos.mooc.huangzhaokun.top/moocImg/vuecode.png"
                 alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="web-introduction">
      <div class="container ">
        <div class="graphic">
          <div><h1 style="color: white">前端</h1></div>
          <img class="webMemberImg animated fadeIn"
               :src="studentUrl" alt="" v-if="imgFlag">
        </div>
        <Scrollama style="margin-top: 200px" @step-enter="stepEnterHandler" :offset="0.9">
          <div class="step" data-step-no="0">
            <el-card class="introBox">
              <h3>颜芬</h3>
              <el-divider></el-divider>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid at autem delectus, distinctio
                enim ipsa neque possimus provident quasi repellendus reprehenderit rerum temporibus. Distinctio dolorem
                doloremque ea harum hic ipsa nam nesciunt obcaecati, perferendis porro quis rem sapiente, suscipit
                temporibus veritatis. Cupiditate itaque magnam reiciendis tempora. Blanditiis culpa, deserunt dicta
                dignissimos facere, illum maxime mollitia nam quas ratione recusandae temporibus, voluptatem
                voluptatibus? Alias animi aperiam atque, error eum facilis fugiat maxime minus molestiae, numquam
                quibusdam quod sed similique velit!</p>
            </el-card>
          </div>
          <div class="step" data-step-no="1">
            <el-card class="introBox">
              <h3>黄照坤</h3>
              <el-divider></el-divider>
              <h4>项目贡献：</h4>
              <p>确定前端架构，框架</p>
              <p>谕学学生端主要负责人，部分学生端页面设计及实现</p>
              <p>页面数据渲染、数据交互</p>
              <p>学生端需求分析，debug，用户体验改进</p>
              <h4>联系方式：</h4>
              <p>Email：565843270@qq.com</p>
            </el-card>
          </div>
          <div class="step" data-step-no="2">
            <el-card class="introBox">
              <h3>蹇滔</h3>
              <el-divider></el-divider>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium asperiores at atque cum
                eaque ex ipsa ipsam ipsum maxime molestias neque nobis non odit officia pariatur perspiciatis porro
                possimus, qui quo ratione rem reprehenderit rerum sequi tempora ullam voluptatem. Ab aliquid architecto
                at consectetur cum debitis dolor ea eveniet illum in incidunt inventore itaque labore, libero molestiae,
                natus odit omnis quam quos recusandae saepe tempore totam ut. Asperiores autem corporis distinctio iste
                natus nostrum numquam quos sint ut velit.</p>
            </el-card>
          </div>
        </Scrollama>
      </div>
    </div>
    <div class="java-introduction">
      <div class="container">
        <el-card class="introBox-L">
          <h3>黄宇玮</h3>
          <el-divider></el-divider>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium asperiores at atque cum
            eaque ex ipsa ipsam ipsum maxime molestias neque nobis non odit officia pariatur perspiciatis porro
            possimus, qui quo ratione rem reprehenderit rerum sequi tempora ullam voluptatem. Ab aliquid architecto
            at consectetur cum debitis dolor ea eveniet illum in incidunt inventore itaque labore, libero molestiae,
            natus odit omnis quam quos recusandae saepe tempore totam ut. Asperiores autem corporis distinctio iste
            natus nostrum numquam quos sint ut velit.</p>
        </el-card>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import 'intersection-observer'
import Scrollama from 'vue-scrollama'
import Footer from '@/components/Footer'

export default {
  name: 'OurTeam',
  data () {
    return {
      imgFlag: true,
      studentUrl: 'https://cos.mooc.huangzhaokun.top/moocImg/MIMU782V8%248D9%40V6%29UO%28DXH.jpg',
      student: [
        'https://cos.mooc.huangzhaokun.top/moocImg/MIMU782V8%248D9%40V6%29UO%28DXH.jpg',
        'https://cos.mooc.huangzhaokun.top/moocImg/huangzhaokun.jpg',
        'https://cos.mooc.huangzhaokun.top/moocImg/jiantao.jpg']
    }
  },
  components: {
    Footer,
    Header,
    Scrollama
  },
  methods: {
    stepEnterHandler ({
      element,
      index,
      direction
    }) {
      if (this.studentUrl !== this.student[index]) {
        this.imgFlag = false
        this.studentUrl = this.student[index]
        setTimeout(() => {
          this.imgFlag = true
        }, 100)
      }
    }
  }
}
</script>

<style scoped>
.graphic {
  position: sticky;
  top: 8%;
  width: 500px;
  height: 500px;
  float: left;
}

.step {
  margin-left: 500px;
}

.pre-introduction {
  height: 500px;
  padding: 100px 0;
}

.web-introduction {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0)), url(https://cos.mooc.huangzhaokun.top/moocImg/kelly-sikkema-TS6FasMlQWs-unsplash%20%281%29_proc.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  padding: 150px 0;
}

.java-introduction {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1)), url(https://cos.mooc.huangzhaokun.top/moocImg/walling-OvLXbURo9Wo-unsplash_proc.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  padding: 100px 0;
}

.teacher-introduction {
  /*background: url(https://cos.mooc.huangzhaokun.top/moocImg/%E5%9B%BE%E7%89%871.png);*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.webMemberImg {
  width: 320px;
  height: 420px;
  object-fit: cover;
  text-align: right;
}

.introBox {
  width: 600px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px) brightness(110%);
  border: 0;
  height: 600px;
  margin-top: 300px;
}

.introBox-L {
  width: 900px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px) brightness(110%);
  border: 0;
  height: 600px;
  color: white;
  margin: 50px auto;
}
</style>
